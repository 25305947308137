<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="policiesEditTitle">
    <div class="modal-dialog" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="policiesEditTitle">{{'editPolicy' | i18n}} - {{name}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="loading">
                <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                <span class="sr-only">{{'loading' | i18n}}</span>
            </div>
            <div class="modal-body" *ngIf="!loading">
                <p>{{description}}</p>
                <app-callout type="warning" *ngIf="type === policyType.TwoFactorAuthentication"
                    title="{{'warning' | i18n}}" icon="fa-warning">
                    {{'twoStepLoginPolicyWarning' | i18n}}
                </app-callout>
                <app-callout type="warning" *ngIf="type === policyType.SingleOrg" title="{{'warning' | i18n}}"
                    icon="fa-warning">
                    {{'singleOrgPolicyWarning' | i18n}}
                </app-callout>
                <ng-container *ngIf="type === policyType.RequireSso">
                    <app-callout type="tip" title="{{'prerequisite' | i18n}}">
                        {{'requireSsoPolicyReq' | i18n}}
                    </app-callout>
                    <app-callout type="warning">
                        {{'requireSsoExemption' | i18n}}
                    </app-callout>
                </ng-container>
                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="enabled" [(ngModel)]="enabled"
                            name="Enabled">
                        <label class="form-check-label" for="enabled">{{'enabled' | i18n}}</label>
                    </div>
                </div>
                <ng-container *ngIf="type === policyType.MasterPassword">
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="masterPassMinComplexity">{{'minComplexityScore' | i18n}}</label>
                            <select id="masterPassMinComplexity" name="MasterPassMinComplexity"
                                [(ngModel)]="masterPassMinComplexity" class="form-control">
                                <option *ngFor="let o of passwordScores" [ngValue]="o.value">{{o.name}}</option>
                            </select>
                        </div>
                        <div class="col-6 form-group">
                            <label for="masterPassMinLength">{{'minLength' | i18n}}</label>
                            <input id="masterPassMinLength" class="form-control" type="number" min="8"
                                name="MasterPassMinLength" [(ngModel)]="masterPassMinLength">
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="masterPassRequireUpper"
                            [(ngModel)]="masterPassRequireUpper" name="MasterPassRequireUpper">
                        <label class="form-check-label" for="masterPassRequireUpper">A-Z</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="masterPassRequireLower"
                            [(ngModel)]="masterPassRequireLower" name="MasterPassRequireLower">
                        <label class="form-check-label" for="masterPassRequireLower">a-z</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="masterPassRequireNumbers"
                            [(ngModel)]="masterPassRequireNumbers" name="MasterPassRequireNumbers">
                        <label class="form-check-label" for="masterPassRequireNumbers">0-9</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="masterPassRequireSpecial"
                            [(ngModel)]="masterPassRequireSpecial" name="MasterPassRequireSpecial">
                        <label class="form-check-label" for="masterPassRequireSpecial">!@#$%^&amp;*</label>
                    </div>
                </ng-container>
                <ng-container *ngIf="type === policyType.PasswordGenerator">
                    <div class="row">
                        <div class="col-6 form-group  mb-0">
                            <label for="passGenDefaultType">{{'defaultType' | i18n}}</label>
                            <select id="passGenDefaultType" name="PassGenDefaultType" [(ngModel)]="passGenDefaultType"
                                class="form-control">
                                <option *ngFor="let o of defaultTypes" [ngValue]="o.value">{{o.name}}</option>
                            </select>
                        </div>
                    </div>
                    <h3 class="mt-4">{{'password' | i18n}}</h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="passGenMinLength">{{'minLength' | i18n}}</label>
                            <input id="passGenMinLength" class="form-control" type="number" name="PassGenMinLength"
                                min="5" max="128" [(ngModel)]="passGenMinLength">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="passGenMinNumbers">{{'minNumbers' | i18n}}</label>
                            <input id="passGenMinNumbers" class="form-control" type="number" name="PassGenMinNumbers"
                                min="0" max="9" [(ngModel)]="passGenMinNumbers">
                        </div>
                        <div class="col-6 form-group">
                            <label for="passGenMinSpecial">{{'minSpecial' | i18n}}</label>
                            <input id="passGenMinSpecial" class="form-control" type="number" name="PassGenMinSpecial"
                                min="0" max="9" [(ngModel)]="passGenMinSpecial">
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseUpper"
                            [(ngModel)]="passGenUseUpper" name="PassGenUseUpper">
                        <label class="form-check-label" for="passGenUseUpper">A-Z</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseLower"
                            [(ngModel)]="passGenUseLower" name="PassGenUseLower">
                        <label class="form-check-label" for="passGenUseLower">a-z</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseNumbers"
                            [(ngModel)]="passGenUseNumbers" name="PassGenUseNumbers">
                        <label class="form-check-label" for="passGenUseNumbers">0-9</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseSpecial"
                            [(ngModel)]="passGenUseSpecial" name="PassGenUseSpecial">
                        <label class="form-check-label" for="passGenUseSpecial">!@#$%^&amp;*</label>
                    </div>
                    <h3 class="mt-4">{{'passphrase' | i18n}}</h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="passGenMinNumberWords">{{'minimumNumberOfWords' | i18n}}</label>
                            <input id="passGenMinNumberWords" class="form-control" type="number"
                                name="PassGenMinNumberWords" min="3" max="20" [(ngModel)]="passGenMinNumberWords">
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenCapitalize"
                            [(ngModel)]="passGenCapitalize" name="PassGenCapitalize">
                        <label class="form-check-label" for="passGenCapitalize">{{'capitalize' | i18n}}</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenIncludeNumber"
                            [(ngModel)]="passGenIncludeNumber" name="PassGenIncludeNumber">
                        <label class="form-check-label" for="passGenIncludeNumber">{{'includeNumber' | i18n}}</label>
                    </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary"
                    data-dismiss="modal">{{'cancel' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
