<div class="container page-content">
    <div class="row">
        <div class="col-3">
            <div class="card" *ngIf="organization">
                <div class="card-header">{{'manage' | i18n}}</div>
                <div class="list-group list-group-flush">
                    <a routerLink="people" class="list-group-item" routerLinkActive="active"
                        *ngIf="organization.isAdmin">
                        {{'people' | i18n}}
                    </a>
                    <a routerLink="collections" class="list-group-item" routerLinkActive="active">
                        {{'collections' | i18n}}
                    </a>
                    <a routerLink="groups" class="list-group-item" routerLinkActive="active"
                        *ngIf="organization.isAdmin && accessGroups">
                        {{'groups' | i18n}}
                    </a>
                    <a routerLink="policies" class="list-group-item" routerLinkActive="active"
                        *ngIf="organization.isAdmin && accessPolicies">
                        {{'policies' | i18n}}
                    </a>
                    <a routerLink="events" class="list-group-item" routerLinkActive="active"
                        *ngIf="organization.isAdmin && accessEvents">
                        {{'eventLogs' | i18n}}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
