<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="sendAddEditTitle">
    <div class="modal-dialog modal-lg" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate
            autocomplete="off">
            <div class="modal-header">
                <h2 class="modal-title" id="sendAddEditTitle">{{title}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="send">
                <div class="row" *ngIf="!editMode">
                    <div class="col-6 form-group">
                        <label for="type">{{'whatTypeOfSend' | i18n}}</label>
                        <select id="type" name="Type" [(ngModel)]="send.type" class="form-control" appAutofocus>
                            <option *ngFor="let o of typeOptions" [ngValue]="o.value">{{o.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="name">{{'name' | i18n}}</label>
                        <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="send.name" required>
                    </div>
                </div>
                <!-- Text -->
                <ng-container *ngIf="send.type === sendType.Text">
                    <div class="form-group">
                        <label for="text">{{'sendTypeText' | i18n}}</label>
                        <textarea id="text" name="Text.Text" rows="6" [(ngModel)]="send.text.text"
                            class="form-control"></textarea>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="send.text.hidden"
                                id="text-hidden" name="Text.Hidden">
                            <label class="form-check-label" for="text-hidden">{{'cfTypeHidden' | i18n}}</label>
                        </div>
                    </div>
                </ng-container>
                <!-- File -->
                <ng-container *ngIf="send.type === sendType.File">
                    <div class="form-group">
                        <div *ngIf="editMode">
                            <strong class="d-block">{{'file' | i18n}}</strong>
                            {{send.file.fileName}} ({{send.file.sizeName}})
                        </div>
                        <div *ngIf="!editMode">
                            <label for="file">{{'file' | i18n}}</label>
                            <input type="file" id="file" class="form-control-file" name="file" required>
                            <small class="form-text text-muted">{{'maxFileSize' | i18n}}</small>
                        </div>
                    </div>
                </ng-container>
                <h3 class="mt-4">{{'options' | i18n}}</h3>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="deletionDate">{{'deletionDate' | i18n}}</label>
                        <input id="deletionDate" class="form-control" type="datetime-local" name="DeletionDate"
                            [(ngModel)]="deletionDate" required>
                    </div>
                    <div class="col-6 form-group">
                        <div class="d-flex">
                            <label for="expirationDate">{{'expirationDate' | i18n}}</label>
                            <a href="#" appStopClick (click)="clearExpiration()" class="ml-auto">
                                {{'clear' | i18n}}
                            </a>
                        </div>
                        <input id="expirationDate" class="form-control" type="datetime-local" name="ExpirationDate"
                            [(ngModel)]="expirationDate">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="maxAccessCount">{{'maxAccessCount' | i18n}}</label>
                        <input id="maxAccessCount" class="form-control" type="number" name="MaxAccessCount"
                            [(ngModel)]="send.maxAccessCount">
                    </div>
                    <div class="col-6 form-group" *ngIf="editMode">
                        <label for="accessCount">{{'currentAccessCount' | i18n}}</label>
                        <input id="accessCount" class="form-control" type="number" name="AccessCount" readonly
                            [(ngModel)]="send.accessCount">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="password" *ngIf="!hasPassword">{{'password' | i18n}}</label>
                        <label for="password" *ngIf="hasPassword">{{'newPassword' | i18n}}</label>
                        <input id="password" class="form-control" type="password" name="Password"
                            [(ngModel)]="password">
                    </div>
                </div>
                <div class="form-group">
                    <label for="notes">{{'notes' | i18n}}</label>
                    <textarea id="notes" name="Notes" rows="6" [(ngModel)]="send.notes" class="form-control"></textarea>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="send.disabled" id="disabled"
                            name="Disabled">
                        <label class="form-check-label" for="disabled">{{'disabled' | i18n}}</label>
                    </div>
                </div>
                <div class="form-group" *ngIf="link">
                    <label for="link">{{'sendLink' | i18n}}</label>
                    <input type="text" readonly id="link" name="Link" [(ngModel)]="link" class="form-control">
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
                    {{'cancel' | i18n}}
                </button>
                <div class="ml-auto" *ngIf="send">
                    <button #deleteBtn type="button" (click)="delete()" class="btn btn-outline-danger"
                        appA11yTitle="{{'delete' | i18n}}" *ngIf="editMode" [disabled]="deleteBtn.loading"
                        [appApiAction]="deletePromise">
                        <i class="fa fa-trash-o fa-lg fa-fw" [hidden]="deleteBtn.loading" aria-hidden="true"></i>
                        <i class="fa fa-spinner fa-spin fa-lg fa-fw" [hidden]="!deleteBtn.loading"
                            title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
